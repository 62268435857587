<template>
  <div class="content">
    <div>
      <a-card style="width: 100%; border-radius: 4px">
        <div class="top-title">
          <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="receipt"
            class="svg-inline--fa fa-receipt fa-fw fa-lg"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            width="32px"
            height="32px"
            style="margin-right: 5px"
          >
            <path
              fill="currentColor"
              d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z"
            ></path>
          </svg>
          {{ $t('学习模块.学习标题') }}
        </div>
        <div class="top-middle">{{ $t('学习模块.学习名称') }}: {{ $route.query.studyName }}</div>
        <div>
          <a-button class="btn" @click="goBack">
            <a-icon type="left" style="font-size: 20px; color: #fff" />
            {{ $t('学习模块.研究') }}
          </a-button>
          <a-button class="btn1" @click="openModal">
            {{ $t('学习模块.添加传感器') }}
            <a-icon type="plus" style="font-size: 20px" />
          </a-button>
          <a-popconfirm placement="rightTop" :ok-text="$t('通用.按钮.确定')" :cancel-text="$t('通用.按钮.取消')" @confirm="confirm">
            <template slot="title">
              <p>Remove test study : {{ $route.query.studyName }}</p>
            </template>
            <a-button class="btn2">
              {{ $t('通用.按钮.删除') }}
              <a-icon type="delete" style="font-size: 20px" />
            </a-button>
          </a-popconfirm>
        </div>
      </a-card>
    </div>
    <div style="margin-top: 16px">
      <a-card style="width: 100%; border-radius: 4px">
        <div>
          <!-- :customRow="handlerowclick" -->
          <a-table rowKey="id" :columns="columns" :data-source="list" :pagination="true" :customRow="handlerowclick">
            <!-- <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span> -->
            <span slot="serialNumber" slot-scope="text, record">
              {{ record.serialNumber }}
            </span>
            <span slot="createTime" slot-scope="text, record">
              {{ record.createTime.substring(5, 7) }}/{{ record.createTime.substring(8, 10) }}/{{
                record.createTime.substring(2, 4)
              }}
            </span>

            <!-- <template slot="operation" slot-scope="text, record">
              <a-popconfirm
                placement="rightTop"
                ok-text="确认"
                cancel-text="取消"
                @confirm="remove(record.id)"
                class="popconfirmClass"
              >
                <template slot="title">
                  <p>确认删除吗？</p>
                </template>
                <a v-hasPermi="['studies:studies:remove']" style="color: red">
                  <a-icon type="delete" />{{ $t('通用.按钮.删除') }}
                </a>
              </a-popconfirm>
            </template> -->
            <span slot="operation" slot-scope="text, record">
              <a @click.stop="remove(record.id)" style="color: red">
                <a-icon type="delete" />{{ $t('通用.按钮.删除') }}
              </a>
            </span>
          </a-table>
        </div>
      </a-card>
    </div>

    <!-- 弹出框 -->
    <pcba-list-modal ref="ModalRefs" @Save="handleSave" />
  </div>
</template>

<script>
// import Table from './modules/table.vue'
// import Modal from './modules/Modal.vue'  delStudies
import { pageStudiesSensor, addStudiesSensor, delStudiesSensor } from '@/api/studiesSensor/studiesSensor'
import { delStudies } from '@/api/studies/studies'
import PcbaListModal from './pcba-list-modal.vue'
import { pageMemory, listMemory, delMemory, listSixMemory } from '@/api/memory/memory'

export default {
  data() {
    return {
      id: this.$route.query.id,
      list: [],
      memoryList: [],
      columns: [
        // {
        //   title: 'StudyId',
        //   dataIndex: 'StudyId',
        //   key: 'StudyId',
        // },
        {
          title: this.$t('学习模块.序列号'),
          dataIndex: 'serialNumber',
          key: 'serialNumber',
        },
        {
          title: this.$t('学习模块.创建时间'),
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: this.$t('学习模块.学习分组'),
          dataIndex: 'studyGroup',
          key: 'studyGroup',
        },
        // {
        //   title: 'sensorsId',
        //   dataIndex: 'sensorsId',
        //   key: 'sensorsId',
        // },
        // {
        //   title: 'remark',
        //   dataIndex: 'remark',
        //   key: 'remark',
        // },
        {
          title: 'operation',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      params: {
        serialNumber: '', //筛选框
        studyGroup: '', //筛选框
        studySubject: '', //筛选框
        studyId: this.$route.query.id, //上一个表格页面的 id
        pageNum: 1,
        pageSize: 10,
      },
    }
  },
  created() {
    // console.log(this.$route.query)
    // this.id = this.$route.query.id
    this.getList()
    this.sixMemoryList()
  },
  components: {
    // Table,
    // Modal
    PcbaListModal,
  },
  methods: {
    openModal() {
      //    this.$refs.ModalRefs.openModal()
    },
    handlerowclick(record, index) {
      return {
        on: {
          click: () => {
            //行单击
            this.$router.push({
              path: '/sensor/sub-sensor',
              query: {
                serialNumber: record.serialNumber,
              },
            })
          },
        },
      }
    },
    // handlerowclick(record) {
    //   console.log(record);
    //   this.$router.push({
    //     path: '/sensor/sub-sensor',
    //     query: {
    //       serialNumber: record.serialNumber,
    //     },
    //   })
    // },
    goBack() {
      this.$router.back()
    },
    getList() {
      pageStudiesSensor(this.params).then((res) => {
        // console.log('res', res)
        this.list = res.data.records
      })
    },
    openModal() {
      this.$refs.ModalRefs.handleOpenModal()
    },
    handleSave(e) {
      //   console.log(e)
      addStudiesSensor({
        studyId: this.id,
        pcbaIdList: e,
      }).then((res) => {
        // console.log('pcba',res)
        this.$message.success('新增成功！')
        this.getList()
      })
    },

    //删除 study name
    confirm() {
      delStudies(this.$route.query.id).then((res) => {
        if (res.message == '成功') {
          this.goBack()
          this.$message.success('删除成功！')
        }
      })
    },
    remove(id) {
         
      delStudiesSensor({
        id: id,
      }).then((res) => {
        if (res.message == '成功') {
          this.$message.success('删除成功')
          this.getList()
        }
      })
    },

    //查询联词搜索
    sixMemoryList() {
      let param = {}
      param.meunType = 'STUDIES'
      listSixMemory(param).then((response) => {
        this.memoryList = response.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn {
    color: rgb(127, 98, 173);
    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    color: #fff;
    // border: none !important;
  }
  .btn1 {
    color: rgb(127, 98, 173);
    background-color: none !important;
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    border: none !important;
  }
  .btn2 {
    color: red !important;
    background-color: none !important;
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    border: none !important;
  }
}
</style>